// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-gen-js": () => import("./../../src/pages/ai-gen.js" /* webpackChunkName: "component---src-pages-ai-gen-js" */),
  "component---src-pages-articles-index-js": () => import("./../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-checkout-js": () => import("./../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-courses-index-js": () => import("./../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-dailyword-index-js": () => import("./../../src/pages/dailyword/index.js" /* webpackChunkName: "component---src-pages-dailyword-index-js" */),
  "component---src-pages-english-starter-test-js": () => import("./../../src/pages/english-starter-test.js" /* webpackChunkName: "component---src-pages-english-starter-test-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pingce-js": () => import("./../../src/pages/pingce.js" /* webpackChunkName: "component---src-pages-pingce-js" */),
  "component---src-pages-products-index-js": () => import("./../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-search-js": () => import("./../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-team-index-js": () => import("./../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-tiwen-js": () => import("./../../src/pages/tiwen.js" /* webpackChunkName: "component---src-pages-tiwen-js" */),
  "component---src-templates-general-js": () => import("./../../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-team-js": () => import("./../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

